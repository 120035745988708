import jQuery from 'jquery';
import '@/assets/js/telerikReportViewer-17.0.23.118.min.js';
import { commonServices } from '@/api/common-services';
import { ROOT, REPORT_URL } from '@/api/constant';
import { booking } from '@/api/booking';
import { getCssText } from '@/api/css-text';
import { courseSetting } from '@/api/course-setting';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
window.$ = jQuery;
const dataGridRef = 'tablePackage';
export default {
  data() {
    return {
      pToken: null,
      pLang: null,
      pTimeZone: null,
      pTimeZoneOffset: null,
      fileName: null,
      ChooseDate: null,
      pUrl: null,
      CourseId: null,
      pUrlReport: null,
      teeSheet: [],
      fields: [],
      htmlBooking: null,
      changeTab: 0,
      courses: [],
      selectedCourse: null,
      hideTeeTime: false,
      isSplitText: false,
      teeTime: [],
      teeTimeSelected: null,
      teeTimeSelectedTypeC: null,
      teeSheetAll: null,
      teeSheetTypeC: [],
      totalPlayer: [],
      BookingStatusStatistic: null,
      listShift: ['ca sáng', 'ca chiều', 'ca tối'],
      TimeShiftMorningEnd: null,
      TimeShiftNightStart: null,
      moringTime: null,
      afternoonTime: null,
      nightTime: null,
      selectedShift: [],
      teeSheetTypeCFilter: [],
      fieldsTable: ['T/T', 'Player', 'Bagtag', 'Caddy', 'Note'],
      filteredTeeSheetTypeC: [], 
      selectedStatus: null,
      selectedStatusTabB: null,
      listStatus: ['Check in', 'Check out', 'Booked'],
      tempFilter: [],
      selectedShiftB: [],
      hideTeeTimeB: false,
      filteredTeeSheetB: [],
    };
  },

  async created() {
    await this.getParam();
    await this.getTimeShift();
    const response = await courseSetting.getListCourse();
    if (response.Status == 200) {
      this.courses = response.Data.Course;
    }
    this.selectedCourse = JSON.parse(this.CourseId)[0];
    await this.getListBooking_RTS01();
  },
  computed: {
    dataGrid() {
      return this.$refs[dataGridRef].instance;
    },
  },
  mounted() {
    this.queryTelerik();
    this.updateFilteredTeeSheetTypeC(); // Update filteredTeeSheetTypeC on mount
    this.handleSelectShiftB();
  },

  watch: {
    selectedStatus: {
      handler(newValue) {
        const statusMap = {
          'Check in': 'CHECK_IN',
          'Check out': 'CHECK_OUT',
          'Booked': 'BOOKED',
        };
  
        // Chuyển selectedStatus sang các giá trị tương ứng
        const statusFilter = newValue.map(status => statusMap[status]);
  
        if (Array.isArray(statusFilter) && statusFilter.length > 0) {
          this.filteredTeeSheetTypeC = this.teeSheetTypeC.map((tee) => ({
            ...tee,
            TeeTime: tee.TeeTime.filter((time) =>
              statusFilter.includes(time.BookingDetailStatus)
            ),
          }));
        } else {
          // Nếu không có trạng thái nào được chọn, trả về toàn bộ teeSheetTypeC
          this.filteredTeeSheetTypeC = [...this.teeSheetTypeC];
        }
      },
      deep: true, // Để Vue phát hiện cả thay đổi sâu trong mảng
    },
    selectedStatusTabB: {
      handler(newValue) {
        const statusMap = {
          'Check in': 'CHECK_IN',
          'Check out': 'CHECK_OUT',
          'Booked': 'BOOKED',
        };
  
        // Chuyển selectedStatusTabB sang các giá trị tương ứng
        const statusFilter = newValue.map(status => statusMap[status]);
  
        if (Array.isArray(statusFilter) && statusFilter.length > 0) {
          this.filteredTeeSheetB = this.teeSheet.map((tee) => ({
            ...tee,
            TeeTime: tee.TeeTime.map((time) => ({
              ...time,
              BookingDetail: time.BookingDetail?.length > 0
                ? time.BookingDetail.filter((bookingDetail) =>
                    bookingDetail.BookingDetailStatus && statusFilter.includes(bookingDetail.BookingDetailStatus)
                  )
                : time.BookingDetail, // Giữ nguyên nếu BookingDetail rỗng
            }))
          }));
        } else {
          // Nếu không có trạng thái nào được chọn, trả về toàn bộ teeSheet
          this.filteredTeeSheetB = [...this.teeSheet];
        }
      },
      deep: true, // Để Vue phát hiện cả thay đổi sâu trong mảng
    },
    hideTeeTime(newValue) {
      this.updateFilteredTeeSheetTypeC();
    },
    teeSheetTypeC: {
      handler() {
        this.updateFilteredTeeSheetTypeC();
      },
      deep: true,
    },
    selectedShiftB() {
      this.handleSelectShiftB();
    },
    hideTeeTimeB() {
      this.handleSelectShiftB();
    },
    teeSheet: {
      handler() {
        this.handleSelectShiftB();
      },
      deep: true,
    },
    pUrl() {
      setTimeout(() => {
        const reportViewer = $('#Ez-teesheet').data('telerik_ReportViewer');
        reportViewer.reportSource({
          report: this.fileName,
          parameters: {
            pToken: this.pToken,
            pUrl: this.pUrl,
            pUrlReport: this.pUrlReport,
            pLang: this.pLang,
            pTimeZone: this.pTimeZone,
            pTimeZoneOffset: this.pTimeZoneOffset,
            ChooseDate: this.ChooseDate,
            CourseId: this.CourseId,
            DatePrint: this.ChooseDate,
          },
        });
      }, 500);
    },
    changeTab(value) {
      if (value == 0) {
        this.queryTelerik();
        const reportViewer = $('#Ez-teesheet').data('telerik_ReportViewer');
        reportViewer.reportSource({
          report: this.fileName,
          parameters: {
            pToken: this.pToken,
            pUrl: this.pUrl,
            pUrlReport: this.pUrlReport,
            pLang: this.pLang,
            pTimeZone: this.pTimeZone,
            pTimeZoneOffset: this.pTimeZoneOffset,
            ChooseDate: this.ChooseDate,
            CourseId: this.CourseId,
            DatePrint: this.ChooseDate,
          },
        });
      } else if (value == 1 || value == 2) {
        // Reset selectedStatusTabB and selectedStatus when tab changes
        this.selectedStatusTabB = null;
        this.selectedStatus = null;
   
     
      }
    },
    selectedCourse() {
      this.getListBooking_RTS01();
    },
  },
  methods: {
    shortTextPrint(code,length) {
      if(this.isSplitText == false)
        return code;
      if (code && code.length > length) {
        return code.substring(0, length) + '';
      }
      return code;
    },
    filterByShiftB() {
      if (this.selectedShiftB.length === 0) {
        // If no shift is selected, display all data
        this.filteredTeeSheetB = this.teeSheet;
      } else if (this.teeSheet?.length > 0) {
        this.filteredTeeSheetB = this.teeSheet.filter((teeSheet) => {
          const teeTime = this.dayjs(teeSheet.Time).toDate(); // Using teeSheet.Time directly
          const timeInMinutes = teeTime.getHours() * 60 + teeTime.getMinutes();

          const morningEndInMinutes = Number(this.TimeShiftMorningEnd);
          const nightStartInMinutes = Number(this.TimeShiftNightStart);

          // Shift filtering conditions based on selected shifts
          const isMorningShift =
            this.selectedShiftB.includes('ca sáng') &&
            timeInMinutes <= morningEndInMinutes;
          const isAfternoonShift =
            this.selectedShiftB.includes('ca chiều') &&
            timeInMinutes > morningEndInMinutes &&
            timeInMinutes < nightStartInMinutes;
          const isEveningShift =
            this.selectedShiftB.includes('ca tối') &&
            timeInMinutes >= nightStartInMinutes;

          // Return true if the time belongs to any selected shift
          return isMorningShift || isAfternoonShift || isEveningShift;
        });
      }
    },

    filterEmptyTeeTimesB() {
      const statusMap = {
        'Check in': 'CHECK_IN',
        'Check out': 'CHECK_OUT',
        'Booked': 'BOOKED',
      };
    
      const statusFilter = (this.selectedStatusTabB || []).map(status => statusMap[status]);
    
      this.filteredTeeSheetB = this.filteredTeeSheetB.map((tee) => ({
        ...tee,
        TeeTime: tee.TeeTime.map((time) => ({
          ...time,
          BookingDetail: time.BookingDetail?.length > 0
            ? time.BookingDetail.filter((bookingDetail) =>
                // Lọc theo trạng thái đã chọn (nếu có)
                (!statusFilter.length || bookingDetail.BookingDetailStatus && statusFilter.includes(bookingDetail.BookingDetailStatus))
              )
            : time.BookingDetail, // Giữ nguyên nếu BookingDetail rỗng
        }))
      }));
    
      // Kiểm tra và loại bỏ các tee không có BookingDetail
      this.filteredTeeSheetB = this.filteredTeeSheetB.filter((tee) => {
        const allTeeTimesEmpty = tee.TeeTime.every(
          (time) => time.BookingDetail.length === 0
        );
        // Nếu cả hai cột đều trống thì loại bỏ hàng, ngược lại giữ lại
        return !allTeeTimesEmpty;
      });
    },
    
    handleSelectShiftB() {
      // Gọi hàm lọc theo ca
      this.filterByShiftB();
    
      // Nếu lựa chọn ẩn tee trống, thì gọi hàm lọc tee trống kết hợp trạng thái
      if (this.hideTeeTimeB) {
        this.filterEmptyTeeTimesB();
      } else {
        // Nếu không ẩn tee trống, vẫn cần lọc theo trạng thái nếu có
        const statusMap = {
          'Check in': 'CHECK_IN',
          'Check out': 'CHECK_OUT',
          'Booked': 'BOOKED',
        };
    
        const statusFilter = (this.selectedStatusTabB || []).map(status => statusMap[status]);
    
        if (Array.isArray(statusFilter) && statusFilter.length > 0) {
          this.filteredTeeSheetB = this.filteredTeeSheetB.map((tee) => ({
            ...tee,
            TeeTime: tee.TeeTime.map((time) => ({
              ...time,
              BookingDetail: time.BookingDetail?.length > 0
                ? time.BookingDetail.filter((bookingDetail) =>
                    bookingDetail.BookingDetailStatus && statusFilter.includes(bookingDetail.BookingDetailStatus)
                  )
                : time.BookingDetail, // Giữ nguyên nếu BookingDetail rỗng
            }))
          }));
        }
      }
    },
    

    handleSelectShift(selectedShifts) {
      const statusMap = {
        'Check in': 'CHECK_IN',
        'Check out': 'CHECK_OUT',
        'Booked': 'BOOKED',
      };
    
      const statusFilter = (this.selectedStatus || []).map(status => statusMap[status]);
    
      if (selectedShifts.length === 0) {
        // Nếu không có ca nào được chọn, hiển thị toàn bộ dữ liệu
        this.updateFilteredTeeSheetTypeC();
      } else if (this.teeSheetTypeC?.length > 0) {
        this.filteredTeeSheetTypeC = this.teeSheetTypeC.map((teeSheet) => ({
          ...teeSheet,
          TeeTime: teeSheet.TeeTime.filter((x) => {
            const openDateTime = this.dayjs(x.OpenDateTime).toDate();
            const timeInMinutes =
              openDateTime.getHours() * 60 + openDateTime.getMinutes();
    
            const morningEndInMinutes = Number(this.TimeShiftMorningEnd);
            const nightStartInMinutes = Number(this.TimeShiftNightStart);
    
            // Điều kiện lọc cho các ca
            const isMorningShift =
              selectedShifts.includes('ca sáng') &&
              timeInMinutes <= morningEndInMinutes;
            const isAfternoonShift =
              selectedShifts.includes('ca chiều') &&
              timeInMinutes > morningEndInMinutes &&
              timeInMinutes < nightStartInMinutes;
            const isEveningShift =
              selectedShifts.includes('ca tối') &&
              timeInMinutes >= nightStartInMinutes;
    
            // Trả về true nếu thời gian thuộc bất kỳ ca nào được chọn
            const isInSelectedShift =
              isMorningShift || isAfternoonShift || isEveningShift;
    
            // Điều kiện lọc theo trạng thái
            const isInSelectedStatus =
              !statusFilter.length || statusFilter.includes(x.BookingDetailStatus);
    
            return isInSelectedShift && isInSelectedStatus;
          }),
        }));
    
        // Lọc tiếp dựa trên trạng thái ẩn tee time nếu cần
        if (this.hideTeeTime) {
          this.filteredTeeSheetTypeC = this.filteredTeeSheetTypeC.map((tee) => ({
            ...tee,
            TeeTime: tee.TeeTime.filter(
              (time) =>
                time.FullName ||
                time.Caddy.CaddyCode ||
                time.BagtagCode ||
                time.Note
            ),
          }));
        }
        this.tempFilter = this.filteredTeeSheetTypeC;
      }
    },
    

    updateFilteredTeeSheetTypeC() {
      // Tạo ánh xạ giữa các trạng thái và mã tương ứng
      const statusMap = {
        'Check in': 'CHECK_IN',
        'Check out': 'CHECK_OUT',
        'Booked': 'BOOKED',
      };
    
      // Lọc trạng thái đã chọn
      const statusFilter = (this.selectedStatus || []).map(status => statusMap[status]);
    
      this.filteredTeeSheetTypeC = this.teeSheetTypeC.map((tee) => ({
        ...tee,
        TeeTime: tee.TeeTime.filter((time) => {
          const openDateTime = this.dayjs(time.OpenDateTime).toDate();
          const timeInMinutes =
            openDateTime.getHours() * 60 + openDateTime.getMinutes();
    
          const morningEndInMinutes = Number(this.TimeShiftMorningEnd);
          const nightStartInMinutes = Number(this.TimeShiftNightStart);
    
          // Điều kiện lọc theo ca
          let isInSelectedShift = true; // Mặc định là true nếu không chọn ca nào
    
          if (this.selectedShift && this.selectedShift.length > 0) {
            const isMorningShift =
              this.selectedShift.includes('ca sáng') &&
              timeInMinutes <= morningEndInMinutes;
            const isAfternoonShift =
              this.selectedShift.includes('ca chiều') &&
              timeInMinutes > morningEndInMinutes &&
              timeInMinutes < nightStartInMinutes;
            const isEveningShift =
              this.selectedShift.includes('ca tối') &&
              timeInMinutes >= nightStartInMinutes;
    
            // Cập nhật isInSelectedShift dựa trên điều kiện lọc ca
            isInSelectedShift =
              isMorningShift || isAfternoonShift || isEveningShift;
          }
    
          // Điều kiện lọc theo trạng thái (nếu có)
          const isInSelectedStatus = 
            !statusFilter.length || statusFilter.includes(time.BookingDetailStatus);
    
          // Kết hợp lọc ca, trạng thái và điều kiện hideTeeTime
          const isRelevantTeeTime =
            time.FullName || time.Caddy.CaddyCode || time.BagtagCode || time.Note;
    
          return isInSelectedShift && isInSelectedStatus && (!this.hideTeeTime || isRelevantTeeTime);
        }),
      }));
    },
    

    getInvoice() {
      $('#Ez-teesheet').telerik_ReportViewer({
        serviceUrl: REPORT_URL,
        reportSource: {
          report: this.fileName,
          parameters: {
            pToken: this.pToken,
            pUrl: this.pUrl,
            pUrlReport: this.pUrlReport,
            pLang: this.pLang,
            pTimeZone: this.pTimeZone,
            pTimeZoneOffset: this.pTimeZoneOffset,
            ChooseDate: this.ChooseDate,
            CourseId: this.CourseId,
            DatePrint: this.ChooseDate,
          },
        },
        viewMode: telerikReportViewer.ViewModes.PRINT_PREVIEW,
        scaleMode: telerikReportViewer.ScaleModes.SPECIFIC,
        scale: 1.0,
        sendEmail: { enabled: false },
      });
    },
    async api_RCF00() {
      await commonServices.api_RCF00().then((response) => {
        this.pUrl = response.Data.find(
          (x) => x.SettingCode == 'SITE_API_URL'
        ).SettingValue;
        this.pUrlReport = `${
          response.Data.find((x) => x.SettingCode === 'SITE_API_URL')
            .SettingValue
        }/rp`;
      });
    },
    getParam() {
      const userGolfData = JSON.parse(localStorage.getItem('userGolfData'));
      this.pLang = localStorage.getItem('systemLanguage') || '1000000';
      this.pToken = userGolfData ? userGolfData.AccessToken : '';
      this.pTimeZone = localStorage.getItem('timeZone');
      this.pTimeZoneOffset = localStorage.getItem('timeZoneOffset');
      this.ChooseDate = this.$route.params.time;
      this.CourseId = this.$route.query.C;
      this.api_RCOM01().then(() => {
        this.api_RCF00();
      });
    },
    async api_RCOM01() {
      const body = {
        KeyGroup: 'REPORT_CONFIG',
      };
      await commonServices.getCommon(body).then((response) => {
        this.fileName = response.Data.find(
          (x) => x.KeyCode == 'TEESHEET_REPORT_FILE_NAME'
        ).KeyValue;
      });
    },
    customStyleTelerik() {
      const buttonFooterParam = document.querySelector(
        '.trv-parameters-area-footer button'
      );
      buttonFooterParam.innerHTML = this.$t('golf_common_search');
      buttonFooterParam.classList.add('buttonFooterParam');
    },
    queryTelerik() {
      $('#Ez-teesheet').telerik_ReportViewer({
        serviceUrl: REPORT_URL,
        viewMode: telerikReportViewer.ViewModes.PRINT_PREVIEW,
        scaleMode: telerikReportViewer.ScaleModes.SPECIFIC,
        scale: 1.0,
        sendEmail: { enabled: false },
      });
    },
    async getListBooking_RTS01() {
      this.fields = [];
      this.teeSheet = [];
      const statusFilter = [];
      if (
        Array.isArray(this.selectedStatus) &&
        this.selectedStatus.length > 0
      ) {
        if (this.selectedStatus.includes('Check in')) {
          statusFilter.push('CHECK_IN');
        }
        if (this.selectedStatus.includes('Check out')) {
          statusFilter.push('CHECK_OUT');
        }
        if (this.selectedStatus.includes('Booked')) {
          statusFilter.push('BOOKED');
        }
      }
      if (
        Array.isArray(this.selectedStatusTabB) &&
        this.selectedStatusTabB.length > 0
      ) {
        if (this.selectedStatusTabB.includes('Check in')) {
          statusFilter.push('CHECK_IN');
        }
        if (this.selectedStatusTabB.includes('Check out')) {
          statusFilter.push('CHECK_OUT');
        }
        if (this.selectedStatusTabB.includes('Booked')) {
          statusFilter.push('BOOKED');
        }
      }
      const data = {
        Date: this.ChooseDate,
        CourseId: [this.selectedCourse],
        Status: statusFilter,
      };
      const response = await booking.getListBooking(data);
      if (response.Status === '200') {
        this.teeSheetTypeC = [];

        const teeNames = response.Data?.TeeSheet[0].TeeTime.map(
          (tee) => tee.TeeName
        );
        this.teeTime = response.Data?.TeeSheet[0].TeeTime.map(
          (tee) => tee.TeeName
        );

        if(this.teeTime!=null)
          this.teeTime = this.teeTime.sort();

        if (this.teeTime.length <= 2) {
          this.teeTimeSelectedTypeC = this.teeTime.sort();
        } else if (this.teeTime.length > 2) {
          this.teeTimeSelectedTypeC = [this.teeTime[0], this.teeTime[1]].sort();
        }
       
        this.teeSheetAll = JSON.parse(JSON.stringify(response?.Data?.TeeSheet));
        this.teeTimeSelectedTypeC.forEach((x) => {
          const TeeTimeTemp = [];
          this.teeSheetAll.forEach((y) => {
            y.TeeTime.filter((tee) => tee.TeeName == x).forEach((z) => {
              for (let i = 0; i < z.NumberOfGolfer; i++) {
                TeeTimeTemp.push({
                  OpenDateTime: y.Time,
                  FinishDateTime: y.TimeTurn,
                  FullName: z.BookingDetail[i]?.FullName || null,
                  Caddy: {
                    CaddyCode: z.BookingDetail[i]?.Caddy?.CaddyCode || null,
                  },
                  BagtagCode: z.BookingDetail[i]?.BagtagCode || null,
                  BookingDetailStatus:z.BookingDetail[i]?.BookingDetailStatus || null,
                  Note: z.BookingDetail[i]?.Note || null,
                  RepresentativeNote:
                    z.BookingDetail[i]?.RepresentativeNote || null,
                  index: i,
                  NumberOfGolfer: z.NumberOfGolfer,
                });
              }
            });
          });
          TeeTimeTemp.sort(
            (a, b) => new Date(a.OpenDateTime) - new Date(b.OpenDateTime)
          );
          this.teeSheetTypeC.push({
            TeeName: x,
            TeeTime: TeeTimeTemp,
          });
        });
      
        if (this.teeTime.length == 2) {
          this.teeTimeSelected = this.teeTime;
          this.fields = ['TEETIME', ...teeNames];
          this.teeSheet = response?.Data?.TeeSheet;
        } else {
          this.teeTimeSelected = [this.teeTime[0], this.teeTime[1]];
          this.fields = ['TEETIME', this.teeTime[0], this.teeTime[1]];
          const teesheetTemp = [];
          response?.Data?.TeeSheet.forEach((x) => {
            x.TeeTime = x.TeeTime.filter((y) =>
              this.teeTimeSelected.includes(y.TeeName)
            );
            teesheetTemp.push(x);
          });
          this.teeSheet = teesheetTemp;
        }
      }
    },
    countPlayersWithFullName(array) {
      let count = 0;
      array.forEach((item) => {
        if (item.FullName) {
          count += 1;
        }
      });
      return count;
    },
    countTotalPlayers() {
      if(!this.teeTimeSelectedTypeC || !Array.isArray(this.teeTimeSelectedTypeC))  {
        return 0;
      }
      return this.teeTimeSelectedTypeC.reduce(
        (total, tee) => total + this.countPlayersForSelectedTee(tee),
        0
      );
    },
    countPlayersForSelectedTee(teeName) {
      let count = 0;
      const teeSheet = this.filteredTeeSheetTypeC.find(
        (sheet) => sheet.TeeName === teeName
      );
      if (teeSheet) {
        count = this.countPlayersWithFullName(teeSheet.TeeTime);
      }
      return count;
    },
    countPlayersWithFullName(array) {
      let count = 0;
      array.forEach((item) => {
        if (item.FullName) {
          count += 1;
        }
      });
      return count;
    },

    countPlayersWithFullNameB(array) {
      let count = 0;
      array.forEach((item) => {
        if (item.FullName!==null) {
          count += 1;
        }
      });
      return count;
    },
    
    countTotalPlayersB() {
      if(!this.teeTimeSelected || !Array.isArray(this.teeTimeSelected))  {
        return 0;
      }
      return this.teeTimeSelected.reduce(
        (total, tee) => total + this.countPlayersForSelectedTeeB(tee),
        0
      );
    },
    
    countPlayersForSelectedTeeB(teeName) {
      let count = 0;
     
      this.filteredTeeSheetB.forEach((sheet) => {
        sheet.TeeTime.forEach((time) => {
          if (time.TeeName === teeName) {
              // Thêm dòng log này
            count += this.countPlayersWithFullNameB(time.BookingDetail);
          }
        });
      });
    
      return count;
    },
    
    showTeeTime(numTable) {
      return this.teeTimeSelectedTypeC && 
             Array.isArray(this.teeTimeSelectedTypeC) && 
             this.filteredTeeSheetTypeC && 
             this.filteredTeeSheetTypeC[numTable] &&
             this.teeTimeSelectedTypeC.includes(this.filteredTeeSheetTypeC[numTable].TeeName);
    },


    async getTimeShift() {
      await booking
        .getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' })
        .then((res) => {
          if (res.Status === '200') {
            this.TimeShiftMorningEnd = res?.Data.find(
              (item) => item.KeyCode === 'BOOKING_SHIFT_MORNING_END'
            ).KeyValue;
            this.TimeShiftNightStart = res?.Data.find(
              (item) => item.KeyCode === 'BOOKING_SHIFT_NIGHT_START'
            ).KeyValue;

            this.moringTime = this.dayjs(this.getUTC('start', 'day'))
              .utc()
              .add(Number(this.TimeShiftMorningEnd), 'minutes')
              .format();
            this.afternoonTime = this.dayjs(this.getUTC('start', 'day'))
              .utc()
              .add(Number(this.TimeShiftNightStart), 'minutes')
              .format();
            this.nightTime = this.dayjs(this.getUTC('start', 'day'))
              .utc()
              .add(24, 'hours')
              .format();
          }
        });
    },
    printBooking(id) {
      const prtHtml = document.getElementById(id).innerHTML;
      let stylesHtml = `<style type="text/css">.ez-icon {display: none} ${getCssText()}</style>`;
      for (const node of [...document.querySelectorAll('link')]) {
        stylesHtml += node.outerHTML;
      }
      const totalGuests = this.filteredTeeSheetTypeC.reduce(
        (total, teeSheet) =>
          total + this.countPlayersWithFullName(teeSheet.TeeTime),
        0
      );
      let shiftSelectedHtmlRender = '';
      let shiftSelectedHtml = '';

      if (this.selectedShift && this.selectedShift.length) {
        this.selectedShift.forEach((shift, index) => {
          shiftSelectedHtml += shift;
          if (index < this.selectedShift.length - 1) {
            shiftSelectedHtml += ',';
          }
        });
      }

      if (shiftSelectedHtml) {
        shiftSelectedHtmlRender = `(${shiftSelectedHtml})`;
      }

      // <template v-if="selectedShift && selectedShift.length">
      //           (<span v-for="(shift, index) in selectedShift" :key="index">
      //             {{ shift
      //             }}<span v-if="index < selectedShift.length - 1"
      //               >,
      //             </span> </span
      //           >)
      //         </template>

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,height=1500,toolbar=0,scrollbars=0,status=0'
      );

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
    
          <style>
          body {
      font-family: "Roboto", Helvetica, Arial, serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.45;
      color: #424244;
    }
    
     body {
      margin: 0;
      background-color: #f8f8f8;
    }
      .d-flex {
      display: flex !important;
    }
         .table.table-booking-print {
	 border-collapse: collapse!important;
	 border-spacing: 0!important;
   border: none;
}
 .table.table-booking-print .first-row-tr .first-row-td:first-child {
	 border-left: 1px solid #000;
	 border-bottom: 1px solid #000;
}
 .table.table-booking-print .last-row-tr td {
	 border-bottom: 1px solid #000;
}
 .table.table-booking-print tr th.table-head {
	 border-top: 1px solid #000;
	 border-right: 1px solid #000;
   border-bottom: 1px solid #000;
	 text-align: center;
	 padding-left: 0px;
	 padding-right: 0px;
}
 .table.table-booking-print tr th.table-head:first-child {
	 border-left: 1px solid #000;
}
 .table.table-booking-print tr td {
	 border-right: 1px solid #ebe9f1;
   border-bottom: 1px solid #ebe9f1;
}
 .table.table-booking-print tr td:last-child {
	 border-right: 1px solid #000;
}
 .table.table-booking-print tr:last-child td {
	//  border-bottom: 1px solid #000;
}
 
 </style>
        </head>
        <body>
          <div style="text-align: center; margin: 10px">
            <h2>Danh sách booking(${totalGuests}) ${shiftSelectedHtmlRender}</h2>
            <i>Thời gian: ${this.utcConvertToLocalTimeZone(
              this.ChooseDate,
              'DD/MM/YYYY'
            )}</i>
            <div>
          </div>
          </div>
          
          ${prtHtml}
          <style>@media print { @page { margin:20px; } *{font-family:  Arial, Helvetica, sans-serif ;} }</style>
          <script> </script>
        </body>
      </html>`);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
    },
    limiter(value) {
      if (value.length > 2) {
        value.pop();
        this.showToast('error', 'Chọn tối đa 2 teetime');
      } else if (value.length > 0) {
        this.teeSheet = [];
        this.fields = ['TEETIME', ...this.teeTimeSelected];
        const teesheetTemp = [];
        const teesheet = JSON.parse(JSON.stringify(this.teeSheetAll));
        teesheet.forEach((x) => {
          x.TeeTime = x.TeeTime.filter((y) =>
            this.teeTimeSelected.includes(y.TeeName)
          );
          teesheetTemp.push(x);
        });
        this.teeSheet = teesheetTemp;
      } else {
        this.showToast('error', 'Vui lòng chọn teetime');
      }
    },
    limiterTypeC(value) {
      if (value.length > 2) {
        value.pop();
        this.showToast('error', 'Chọn tối đa 2 teetime');
      } else if (value.length > 0) {
        this.teeSheetTypeC = [];
        this.teeTimeSelectedTypeC.forEach((x) => {
          const TeeTimeTemp = [];
          this.teeSheetAll.forEach((y) => {
            y.TeeTime.filter((tee) => tee.TeeName == x).forEach((z) => {
              for (let i = 0; i < z.NumberOfGolfer; i++) {
                TeeTimeTemp.push({
                  OpenDateTime: y.Time,
                  FinishDateTime: y.TimeTurn,
                  FullName: z.BookingDetail[i]?.FullName || null,
                  Caddy: {
                    CaddyCode: z.BookingDetail[i]?.Caddy?.CaddyCode || null,
                  },
                  BagtagCode: z.BookingDetail[i]?.BagtagCode || null,
                  BookingDetailStatus:z.BookingDetail[i]?.BookingDetailStatus || null,
                  Note: z.BookingDetail[i]?.Note || null,
                  RepresentativeNote:
                    z.BookingDetail[i]?.RepresentativeNote || null,
                  index: i,
                  NumberOfGolfer: z.NumberOfGolfer,
                });
              }
            });
          });
          TeeTimeTemp.sort(
            (a, b) => new Date(a.OpenDateTime) - new Date(b.OpenDateTime)
          );
          this.teeSheetTypeC.push({
            TeeName: x,
            TeeTime: TeeTimeTemp,
          });
        });
        this.filteredTeeSheetTypeC = this.teeSheetTypeC ;
        
      } else {
        this.showToast('error', 'Vui lòng chọn teetime');
      }
    },
    exportExcel() {
      const tablePackage1 = this.$refs.tablePackage1; // Tham chiếu tới bảng 1
      const tablePackage2 = this.$refs.tablePackage2; // Tham chiếu tới bảng 2
      if (!tablePackage1 || !tablePackage2) {
        this.showToast('error', 'Bảng dữ liệu chưa được tải.');
        return;
      }

      const workbook = new Workbook(); // Tạo workbook Excel mới
      const worksheet = workbook.addWorksheet('Danh Sách Booking'); // Tạo một worksheet duy nhất

      // --- Xử lý bảng 1 ---
      const headerRow1 = worksheet.getRow(1);
      headerRow1.getCell(1).value = this.teeTimeSelected[0]; // Tiêu đề bảng 1
      headerRow1.font = { bold: true };
      for (let i = 1; i <= 5; i++) {
        headerRow1.getCell(i).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      }

      let currentRowIndex = 2; // Bắt đầu từ hàng thứ 2 cho dữ liệu
      let lastTeeTime = null; // Biến lưu TeeTime cuối cùng
      let mergeStartRow = null; // Biến lưu dòng bắt đầu hợp nhất

      tablePackage1.$el.querySelectorAll('tr').forEach((row, rowIndex) => {
        const cellValues = [...row.querySelectorAll('th, td')].map((cell) =>
          cell.innerText.trim()
        );

        let teeTimeValue, playerName, bagTag, caddy, note;

        if (cellValues.length === 5) {
          teeTimeValue = cellValues[0];
          playerName = cellValues[1];
          bagTag = cellValues[2];
          caddy = cellValues[3];
          note = cellValues[4];

          // Only merge if this is not the first row and TeeTime has changed
          if (lastTeeTime !== null && teeTimeValue !== lastTeeTime) {
            worksheet.mergeCells(`A${mergeStartRow}:A${currentRowIndex - 1}`);
            mergeStartRow = currentRowIndex;
          }

          if (lastTeeTime === null) {
            mergeStartRow = currentRowIndex; // Initialize merge start row
          }

          lastTeeTime = teeTimeValue;
        } else if (cellValues.length === 4) {
          playerName = cellValues[0];
          bagTag = cellValues[1];
          caddy = cellValues[2];
          note = cellValues[3];
        }

        const excelRow = worksheet.addRow([]);
        excelRow.getCell(1).value =
          teeTimeValue === lastTeeTime ? teeTimeValue : '';
        excelRow.getCell(1).alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
        excelRow.getCell(1).border = {
          left: { style: 'thin' },
          right: { style: 'thin' },
          bottom: { style: 'thin' }
        };
        excelRow.getCell(2).value = playerName || '';
        excelRow.getCell(3).value = bagTag || '';
        excelRow.getCell(4).value = caddy || '';
        excelRow.getCell(5).value = note || '';
        for (let i = 2; i <= 5; i++) {
          excelRow.getCell(i).border = {
            left: { style: 'thin' },
            right: { style: 'thin' },
            bottom: { style: 'thin' }
          };
        }
        currentRowIndex++;
      });

      if (mergeStartRow !== null && currentRowIndex > mergeStartRow + 1) {
        worksheet.mergeCells(`A${mergeStartRow}:A${currentRowIndex - 1}`);
      }

      // Tùy chỉnh độ rộng cột cho bảng 1
      worksheet.getColumn(1).width = 10; // TeeTime
      worksheet.getColumn(2).width = 20; // Người chơi
      worksheet.getColumn(3).width = 20; // Bagtag
      worksheet.getColumn(4).width = 20; // Caddy
      worksheet.getColumn(5).width = 30; // Ghi chú

      // --- Xử lý bảng 2, bắt đầu từ cột G ---
      const headerRow2 = worksheet.getRow(1);
      headerRow2.getCell(7).value = this.teeTimeSelected[1]; // Tiêu đề bảng 2
      headerRow2.font = { bold: true };
      for (let i = 7; i <= 11; i++) {
        headerRow2.getCell(i).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      }
      currentRowIndex = 2; // Bắt đầu từ hàng thứ 2 (cùng hàng với bảng 1)
      lastTeeTime = null;
      mergeStartRow = null;

      tablePackage2.$el.querySelectorAll('tr').forEach((row, rowIndex) => {
        const cellValues = [...row.querySelectorAll('th, td')].map((cell) =>
          cell.innerText.trim()
        );

        let teeTimeValue, playerName, bagTag, caddy, note;

        if (cellValues.length === 5) {
          teeTimeValue = cellValues[0];
          playerName = cellValues[1];
          bagTag = cellValues[2];
          caddy = cellValues[3];
          note = cellValues[4];

          if (lastTeeTime !== null && teeTimeValue !== lastTeeTime) {
            worksheet.mergeCells(`G${mergeStartRow}:G${currentRowIndex - 1}`);
            mergeStartRow = currentRowIndex;
          }

          if (lastTeeTime === null) {
            mergeStartRow = currentRowIndex; // Initialize merge start row
          }

          lastTeeTime = teeTimeValue;
        } else if (cellValues.length === 4) {
          playerName = cellValues[0];
          bagTag = cellValues[1];
          caddy = cellValues[2];
          note = cellValues[3];
        }

        const excelRow =
          worksheet.getRow(currentRowIndex) || worksheet.addRow([]);
        excelRow.getCell(7).value =
          teeTimeValue === lastTeeTime ? teeTimeValue : '';
        excelRow.getCell(7).alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
        excelRow.getCell(7).border = {
          left: { style: 'thin' },
          right: { style: 'thin' },
          bottom: { style: 'thin' }
        };
    

        excelRow.getCell(8).value = playerName || '';
        excelRow.getCell(9).value = bagTag || '';
        excelRow.getCell(10).value = caddy || '';
        excelRow.getCell(11).value = note || '';
        for (let i = 8; i <= 11; i++) {
          excelRow.getCell(i).border = {
            left: { style: 'thin' },
            right: { style: 'thin' },
            bottom: { style: 'thin' }
          };
        }
        currentRowIndex++;
      });

      if (mergeStartRow !== null && currentRowIndex > mergeStartRow + 1) {
        worksheet.mergeCells(`G${mergeStartRow}:G${currentRowIndex - 1}`);
      }

      // Tùy chỉnh độ rộng cột cho bảng 2
      worksheet.getColumn(7).width = 10; // TeeTime
      worksheet.getColumn(8).width = 20; // Người chơi
      worksheet.getColumn(9).width = 20; // Bagtag
      worksheet.getColumn(10).width = 20; // Caddy
      worksheet.getColumn(11).width = 30; // Ghi chú

      // Lưu file Excel vào máy của người dùng
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Danh_Sach_Booking.xlsx'
        );
      });
    },

    exportExcelTabB() {
      const tablePackageB = this.$refs.tablePackageB; // Tham chiếu đến bảng Tab B

      if (!tablePackageB) {
        this.showToast('error', 'Bảng dữ liệu chưa được tải.');
        return;
      }

      const workbook = new Workbook(); // Tạo một workbook Excel mới
      const worksheetB = workbook.addWorksheet('TeeSheet B'); // Thêm một worksheet cho Tab B

      // Bắt đầu điền dữ liệu từ hàng thứ 2
      tablePackageB.$el.querySelectorAll('tr').forEach((row, rowIndex) => {
        const cellValues = [...row.querySelectorAll('th, td')].map((cell) =>
          cell.innerText.trim()
        );

        if (cellValues.length > 0) {
          const excelRow = worksheetB.addRow([]); // Thêm hàng mới vào Excel

          // Tee Time trong cột đầu tiên
          excelRow.getCell(1).value = cellValues[0];
          excelRow.getCell(1).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };

          if (
            !cellValues[1].includes('TEE A1') &&
            !cellValues[1].includes('TEE B1')
          ) {
            const input = cellValues[1].replace(/\s+/g, ' ').trim(); // Làm sạch chuỗi, loại bỏ khoảng trắng thừa và các ký tự đặc biệt như \t

            // Áp dụng tách người chơi
            let teeAPlayers = input
              .split('Đoàn:')
              .map((part, index, arr) => {
                // Nếu không phải là phần cuối, thêm lại 'Đoàn:' cho mỗi phần
                return index < arr.length - 1
                  ? part.trim() + '\nĐoàn:'
                  : part.trim();
              })
              .slice(0, -1); // Loại bỏ phần cuối nếu nó rỗng
            excelRow.getCell(2).value = teeAPlayers ? teeAPlayers[0] || '' : ''; // Người chơi 1
            excelRow.getCell(3).value = teeAPlayers ? teeAPlayers[1] || '' : ''; // Người chơi 2
            excelRow.getCell(4).value = teeAPlayers ? teeAPlayers[2] || '' : ''; // Người chơi 3
            excelRow.getCell(5).value = teeAPlayers ? teeAPlayers[3] || '' : ''; // Người chơi 4
            for (let i = 2; i <= 5; i++) {
              excelRow.getCell(i).alignment = {
                vertical: 'middle',
                horizontal: 'left',
                wrapText: true,
              };
              excelRow.getCell(i).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
              };
            }
        
          } else {
            // Nếu gặp 'TEE A1' hoặc 'TEE B1', gộp các ô từ B1 đến E1
            worksheetB.mergeCells(`B${excelRow.number}:E${excelRow.number}`); // Gộp các ô trong hàng hiện tại

            // Gán giá trị cho ô hợp nhất
            worksheetB.getCell(`B${excelRow.number}`).value = cellValues[1];

            // Căn giữa giá trị trong ô hợp nhất
            worksheetB.getCell(`B${excelRow.number}`).alignment = {
              vertical: 'middle',
              horizontal: 'center', // Căn giữa theo chiều ngang
            };
            worksheetB.getCell(`B${excelRow.number}`).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          }
          if (
            !cellValues[2].includes('TEE A1') &&
            !cellValues[2].includes('TEE B1')
          ) {
            const input = cellValues[2].replace(/\s+/g, ' ').trim(); // Làm sạch chuỗi, loại bỏ khoảng trắng thừa và các ký tự đặc biệt như \t

            // Áp dụng tách người chơi
            let teeAPlayers = input
              .split('Đoàn:')
              .map((part, index, arr) => {
                // Nếu không phải là phần cuối, thêm lại 'Đoàn:' cho mỗi phần
                return index < arr.length - 1
                  ? part.trim() + '\nĐoàn:'
                  : part.trim();
              })
              .slice(0, -1); // Loại bỏ phần cuối nếu nó rỗng
            excelRow.getCell(6).value = teeAPlayers ? teeAPlayers[0] || '' : ''; // Người chơi 1
            excelRow.getCell(7).value = teeAPlayers ? teeAPlayers[1] || '' : ''; // Người chơi 2
            excelRow.getCell(8).value = teeAPlayers ? teeAPlayers[2] || '' : ''; // Người chơi 3
            excelRow.getCell(9).value = teeAPlayers ? teeAPlayers[3] || '' : ''; // Người chơi 4
            for (let i = 6; i <= 9; i++) {
              excelRow.getCell(i).alignment = {
                vertical: 'middle',
                horizontal: 'left',
                wrapText: true,
              };
              excelRow.getCell(i).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
              };
            }
          } else {
            // Nếu gặp 'TEE A1' hoặc 'TEE B1', gộp các ô từ B1 đến E1
            worksheetB.mergeCells(`F${excelRow.number}:I${excelRow.number}`); // Gộp các ô trong hàng hiện tại

            // Gán giá trị cho ô hợp nhất
            worksheetB.getCell(`F${excelRow.number}`).value = cellValues[2];

            // Căn giữa giá trị trong ô hợp nhất
            worksheetB.getCell(`F${excelRow.number}`).alignment = {
              vertical: 'middle',
              horizontal: 'center', // Căn giữa theo chiều ngang
            };
            worksheetB.getCell(`F${excelRow.number}`).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          }
        }
      });

      // Tùy chỉnh độ rộng cột để nhìn rõ hơn
      worksheetB.getColumn(1).width = 15;
      worksheetB.getColumn(2).width = 20;
      worksheetB.getColumn(3).width = 20;
      worksheetB.getColumn(4).width = 20;
      worksheetB.getColumn(5).width = 20;
      worksheetB.getColumn(6).width = 20;
      worksheetB.getColumn(7).width = 20;
      worksheetB.getColumn(8).width = 20;
      worksheetB.getColumn(9).width = 20;
      // Lưu file Excel vào hệ thống của người dùng
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Danh_Sach_Booking.xlsx'
        );
      });
    },
  },
};
